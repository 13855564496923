import React from "react";

interface IIcon {
    color?: string;
}

const DluhopisyIcon: React.FC<IIcon> = props => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5 0C10.119 0 9 1.119 9 2.5C9 3.881 10.119 5 11.5 5C11.6733 5 11.8425 4.98276 12.0059 4.94922C12.0056 4.96657 12 4.98259 12 5C12 7.209 13.791 9 16 9C18.209 9 20 7.209 20 5C20 2.791 18.209 1 16 1C15.2019 1 14.4621 1.23976 13.8379 1.64258C13.4869 0.68639 12.5781 0 11.5 0ZM11.5 1.75C11.914 1.75 12.25 2.086 12.25 2.5C12.25 2.914 11.914 3.25 11.5 3.25C11.086 3.25 10.75 2.914 10.75 2.5C10.75 2.086 11.086 1.75 11.5 1.75ZM16 3C17.105 3 18 3.895 18 5C18 6.105 17.105 7 16 7C14.895 7 14 6.105 14 5C14 3.895 14.895 3 16 3ZM5 8C4.709 8 4.43397 8.05386 4.16797 8.13086L0 9.10742V16.3086L4 15L12.1562 17.8535C12.4213 17.9465 12.6967 17.9922 12.9707 17.9922C13.3727 17.9922 13.7728 17.8952 14.1348 17.7012L21.4629 13.7832L20.2852 11.2676L13.1211 14.8809L12.4883 14.6953L13.0078 13.4277C13.4628 12.4127 13.0024 11.2204 11.9824 10.7754L6.11523 8.2207C5.76923 8.0817 5.395 8 5 8ZM5 10C5.114 10 5.23161 10.0224 5.34961 10.0664L11.1816 12.6074L11.168 12.6387L11.1543 12.6699L10.6367 13.9375L10.1855 15.0449L4.66016 13.1113L4.02148 12.8887L3.37891 13.0977L2 13.5508V10.6934L4.62305 10.0781L4.67578 10.0664L4.72461 10.0527C4.84461 10.0187 4.937 10 5 10Z"
                fill={props.color}
            />
        </svg>
    );
};

export default DluhopisyIcon;
