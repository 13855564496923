import React, { useState, useEffect } from "react";
import {
    Wrap,
    Title,
    ContentWrap,
    Content,
    ContentTitle,
    ContentText,
} from "./styled";
import DictionaryMenu from "./DictionaryMenu";

import { dictionaryData } from "../../data/dictionary";
import BigIcon from "./DictionaryMenu/icons/BigIcon";

const Dictionary: React.FC = () => {
    const [currentMenuIndex, setCurrentMenuIndex] = useState(0);
    const [animated, setAnimated] = useState(false);

    const switchMenu = (index: number) => {
        if (index !== currentMenuIndex) {
            setAnimated(false);
            setTimeout(() => {
                setAnimated(true);
            }, 20);
        }

        setCurrentMenuIndex(index);
    };

    useEffect(() => {
        setAnimated(true);
    }, []);

    return (
        <Wrap>
            <Title data-aos="fade-up" data-aos-delay="200">
                Slovník
            </Title>
            <ContentWrap>
                <DictionaryMenu
                    menuState={currentMenuIndex}
                    setMenu={index => switchMenu(index)}
                />
                <Content animated={animated}>
                    <BigIcon type={dictionaryData[currentMenuIndex].icon} />
                    <ContentTitle>
                        {dictionaryData[currentMenuIndex].title}
                    </ContentTitle>
                    <ContentText>
                        {dictionaryData[currentMenuIndex].content}
                    </ContentText>
                </Content>
            </ContentWrap>
        </Wrap>
    );
};

export default Dictionary;
