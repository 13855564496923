import React from "react";

interface IIcon {
    color?: string;
}

const ProfilIcon: React.FC<IIcon> = props => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22 2L16.6992 2.80078L18.2422 4.34375L15.1777 7.4082L13.5684 5.90039L9.49219 10.0781L8.00781 8.59375L3.30273 13.2031L4.70312 14.6309L7.99219 11.4062L9.50781 12.9219L13.6309 8.69922L15.2227 10.1914L19.6562 5.75781L21.1992 7.30078L22 2ZM19 10V21H21V10H19ZM15 12V21H17V12H15ZM11 14V21H13V14H11ZM7 16V21H9V16H7ZM3 18V21H5V18H3Z"
                fill={props.color}
            />
        </svg>
    );
};

export default ProfilIcon;
