import React from "react";

interface IIcon {
    color?: string;
}

const AkcieIcon: React.FC<IIcon> = props => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 24 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 0V2H7V0H5ZM17 0V2H19V0H17ZM16.9922 2.96875L8.96094 9.49414L5.45898 6.99219L0.117188 11.3496L1.38281 12.9004L5.54102 9.50781L9.03906 12.0059L17.0078 5.53125L20.0352 7.92773L23.9121 4.49805L22.5879 3.00195L19.9648 5.32227L16.9922 2.96875ZM5 4V6H7V4H5ZM17 8V10H19V8H17ZM5 12V14H7V12H5ZM17 12V14H19V12H17ZM5 16V18H7V16H5ZM17 16V18H19V16H17ZM0 20V22H24V20H0Z"
                fill={props.color}
            />
        </svg>
    );
};

export default AkcieIcon;
