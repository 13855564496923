import React from "react";

interface IIcon {
    color?: string;
}

const CertifikatyIcon: React.FC<IIcon> = props => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.5 0C3.36971 0 0 3.36971 0 7.5C0 11.6303 3.36971 15 7.5 15C9.38577 15 11.1074 14.2913 12.4277 13.1348L13 13.707V15L18 20L20 18L15 13H13.707L13.1348 12.4277C14.2913 11.1074 15 9.38577 15 7.5C15 3.36971 11.6303 0 7.5 0ZM7.23828 2.01172V3.01758C6.92928 3.05658 5.19727 3.3817 5.19727 5.4707C5.19727 8.5187 8.35352 7.61639 8.35352 9.65039C8.35352 10.6684 7.69455 10.6934 7.56055 10.6934C7.43855 10.6934 6.63477 10.7572 6.63477 9.19922H4.91016C4.91016 11.8382 6.82438 12.0489 7.10938 12.0879V12.9805C4.24475 12.7809 2 10.4175 2 7.5C2 4.53894 4.31243 2.1481 7.23828 2.01172ZM8.1582 2.04102C10.8939 2.36433 13 4.67381 13 7.5C13 10.3727 10.8249 12.7129 8.02344 12.9746V12.0879C8.33244 12.0489 10.0762 11.7627 10.0762 9.63867C10.0762 6.71367 6.92088 7.27352 6.92188 5.47852C6.92188 4.45052 7.42037 4.41797 7.60938 4.41797C7.85138 4.41797 8.36523 4.61419 8.36523 5.86719H10.0898C10.0898 3.46919 8.4502 3.09702 8.1582 3.04102V2.04102Z"
                fill={props.color}
            />
        </svg>
    );
};

export default CertifikatyIcon;
