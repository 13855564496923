import React from "react";
import MaxWidthContainer from "../../components/MaxWidth";
import Dictionary from "../../components/Dictionary";

const DictionaryPage = () => {
    return (
        <MaxWidthContainer>
            <Dictionary />
        </MaxWidthContainer>
    );
};

export default DictionaryPage;
