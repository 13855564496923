import React from "react";

interface IIcon {
    color?: string;
}

const DluhopisoveFondyIcon: React.FC<IIcon> = props => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 0C1.87013 0.000434205 1.74061 0.0135168 1.61328 0.0390625C1.6027 0.0411798 1.59255 0.0445889 1.58203 0.046875C1.46239 0.0725617 1.34537 0.109174 1.23242 0.15625C1.22726 0.158426 1.22193 0.159891 1.2168 0.162109C0.85623 0.315563 0.548686 0.571559 0.332362 0.898302C0.116038 1.22504 0.000466277 1.60814 0 2V16C0.000434192 16.1299 0.0135168 16.2594 0.0390625 16.3867C0.0411798 16.3973 0.0445889 16.4074 0.046875 16.418C0.0725617 16.5376 0.109174 16.6546 0.15625 16.7676C0.158426 16.7727 0.159891 16.7781 0.162109 16.7832C0.315563 17.1438 0.571559 17.4513 0.898302 17.6676C1.22504 17.884 1.60814 17.9995 2 18H18C18.1299 17.9996 18.2594 17.9865 18.3867 17.9609C18.3973 17.9588 18.4074 17.9554 18.418 17.9531C18.8652 17.8575 19.2662 17.6115 19.5541 17.2561C19.842 16.9007 19.9994 16.4574 20 16V2C19.9996 1.87013 19.9865 1.74061 19.9609 1.61328C19.9588 1.60269 19.9554 1.59257 19.9531 1.58203C19.8575 1.13475 19.6115 0.733752 19.2561 0.44587C18.9007 0.157988 18.4574 0.000620508 18 0H2ZM4 2H16C16 2.53043 16.2107 3.03914 16.5858 3.41421C16.9609 3.78929 17.4696 4 18 4V14C17.4696 14 16.9609 14.2107 16.5858 14.5858C16.2107 14.9609 16 15.4696 16 16H4C4 15.4696 3.78929 14.9609 3.41421 14.5858C3.03914 14.2107 2.53043 14 2 14V4C2.53043 4 3.03914 3.78929 3.41421 3.41421C3.78929 3.03914 4 2.53043 4 2ZM9.71484 3V4.0293C9.37584 4.0723 7.47656 4.42875 7.47656 6.71875C7.47656 10.0597 10.9355 9.07078 10.9355 11.3008C10.9355 12.4168 10.2154 12.4434 10.0684 12.4434C9.94036 12.4434 9.13641 12.489 9.06641 11H7.17188C7.26687 13.702 9.26727 13.9287 9.57227 13.9707V15H10.5742V13.9707C10.9132 13.9277 12.8242 13.6141 12.8242 11.2871C12.8242 8.08111 9.36523 8.69356 9.36523 6.72656C9.36523 5.59956 9.98709 5.56445 10.1211 5.56445C10.3561 5.56445 10.8945 5.775 10.9395 7H12.8242C12.7492 4.523 11.0357 4.11664 10.7227 4.05664V3H9.71484ZM4 8V10H6V8H4ZM14 8V10H16V8H14Z"
                fill={props.color}
            />
        </svg>
    );
};

export default DluhopisoveFondyIcon;
