import styled from "styled-components";
import { media } from "../../../../utils/media";

export const Wrap = styled.div`
    width: 300px;
    height: 100%;

    font-size: 1.4rem;
    line-height: 1.7rem;
    font-family: ${({ theme }) => theme.fonts.verdana};

    display: flex;
    flex-direction: column;

    ${media.tablet`
        width: 385px;    
    `}
    ${media.desktop`
        width: 330px;
    `}
    ${media.bigDesktop`
        width: 320px;
    `}
`;

interface IDictionaryMenuItem {
    active: boolean;
}

export const DictionaryMenuItem = styled.div<IDictionaryMenuItem>`
    width: 100%;
    margin: 0.8em 0;

    color: ${props =>
        props.active ? props.theme.colors.orange : props.theme.colors.blue};
    font-weight: ${props => (props.active ? "bold" : "normal")};

    display: flex;
    align-items: flex-start;

    cursor: pointer;
`;

export const DictionaryMenuIcon = styled.div`
    margin-right: 2em;
`;
