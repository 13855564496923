import React from "react";

import { Wrap, DictionaryMenuItem, DictionaryMenuIcon } from "./styled";

import AkcieIcon from "./icons/AkcieIcon";
import AkcioveFondyIcon from "./icons/AkcioveFondyIcon";
import CertifikatyIcon from "./icons/CertifikatyIcon";
import DluhopisoveFondyIcon from "./icons/DluhopisoveFondyIcon";
import DluhopisyIcon from "./icons/DluhopisyIcon";
import ETFIcon from "./icons/ETFIcon";
import WarrantyIcon from "./icons/WarrantyIcon";
import PrimerenostIcon from "./icons/PrimerenostIcon";
import ProfilIcon from "./icons/ProfilIcon";
import VhodnostIcon from "./icons/VhodnostIcon";
import CilovyTrhIcon from "./icons/CilovyTrhIcon";

interface IDictionaryMenu {
    menuState: number;
    setMenu: (index: number) => void;
}

const EDictionaryMenu = {
    AKCIE: 0,
    AKCIOVE_FONDY: 1,
    DLUHOPISOVE_FONDY: 2,
    DLUHOPISY: 3,
    ETF: 4,
    CERTIFIKATY: 5,
    WARRANTY: 6,
    CILOVYTRH: 7,
    PRIMERENOST: 8,
    PROFIL: 9,
    VHODNOST: 10,
};

const DictionaryMenu: React.FC<IDictionaryMenu> = ({ menuState, setMenu }) => {
    const getMenuItemColor = (index: number) => {
        return menuState === index ? "#F59100" : "#000046";
    };

    return (
        <Wrap data-aos="fade-up" data-aos-delay="300">
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.AKCIE)}
                active={menuState === EDictionaryMenu.AKCIE}
            >
                <DictionaryMenuIcon>
                    <AkcieIcon
                        color={getMenuItemColor(EDictionaryMenu.AKCIE)}
                    />
                </DictionaryMenuIcon>
                Akcie
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.AKCIOVE_FONDY)}
                active={menuState === EDictionaryMenu.AKCIOVE_FONDY}
            >
                <DictionaryMenuIcon>
                    <AkcioveFondyIcon
                        color={getMenuItemColor(EDictionaryMenu.AKCIOVE_FONDY)}
                    />
                </DictionaryMenuIcon>
                Akciové fondy
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.CILOVYTRH)}
                active={menuState === EDictionaryMenu.CILOVYTRH}
            >
                <DictionaryMenuIcon>
                    <CilovyTrhIcon
                        color={getMenuItemColor(EDictionaryMenu.CILOVYTRH)}
                    />
                </DictionaryMenuIcon>
                Cílový trh
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.DLUHOPISOVE_FONDY)}
                active={menuState === EDictionaryMenu.DLUHOPISOVE_FONDY}
            >
                <DictionaryMenuIcon>
                    <DluhopisoveFondyIcon
                        color={getMenuItemColor(
                            EDictionaryMenu.DLUHOPISOVE_FONDY
                        )}
                    />
                </DictionaryMenuIcon>
                Dluhopisové fondy a fondy peněžního trhu
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.ETF)}
                active={menuState === EDictionaryMenu.ETF}
            >
                <DictionaryMenuIcon>
                    <ETFIcon color={getMenuItemColor(EDictionaryMenu.ETF)} />
                </DictionaryMenuIcon>
                ETF
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.CERTIFIKATY)}
                active={menuState === EDictionaryMenu.CERTIFIKATY}
            >
                <DictionaryMenuIcon>
                    <CertifikatyIcon
                        color={getMenuItemColor(EDictionaryMenu.CERTIFIKATY)}
                    />
                </DictionaryMenuIcon>
                Investiční certifikáty
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.DLUHOPISY)}
                active={menuState === EDictionaryMenu.DLUHOPISY}
            >
                <DictionaryMenuIcon>
                    <DluhopisyIcon
                        color={getMenuItemColor(EDictionaryMenu.DLUHOPISY)}
                    />
                </DictionaryMenuIcon>
                Korporátní dluhopisy
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.PRIMERENOST)}
                active={menuState === EDictionaryMenu.PRIMERENOST}
            >
                <DictionaryMenuIcon>
                    <PrimerenostIcon
                        color={getMenuItemColor(EDictionaryMenu.PRIMERENOST)}
                    />
                </DictionaryMenuIcon>
                Přiměřenost
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.PROFIL)}
                active={menuState === EDictionaryMenu.PROFIL}
            >
                <DictionaryMenuIcon>
                    <ProfilIcon
                        color={getMenuItemColor(EDictionaryMenu.PROFIL)}
                    />
                </DictionaryMenuIcon>
                Rizikový profil
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.VHODNOST)}
                active={menuState === EDictionaryMenu.VHODNOST}
            >
                <DictionaryMenuIcon>
                    <VhodnostIcon
                        color={getMenuItemColor(EDictionaryMenu.VHODNOST)}
                    />
                </DictionaryMenuIcon>
                Vhodnost (investiční poradenství)
            </DictionaryMenuItem>
            <DictionaryMenuItem
                onClick={() => setMenu(EDictionaryMenu.WARRANTY)}
                active={menuState === EDictionaryMenu.WARRANTY}
            >
                <DictionaryMenuIcon>
                    <WarrantyIcon
                        color={getMenuItemColor(EDictionaryMenu.WARRANTY)}
                    />
                </DictionaryMenuIcon>
                Warranty
            </DictionaryMenuItem>
        </Wrap>
    );
};

export default DictionaryMenu;
