import styled, { css } from "styled-components";
import { media } from "../../../utils/media";

interface IContent {
    animated: boolean;
}

export const Wrap = styled.div`
    height: auto;
    margin: 60px 50px;

    ${media.tablet`
        margin: 110px 120px;
    `}

    ${media.desktop`
        margin: 110px auto 210px;
        max-width: 1560px;
        padding: 0 180px;
    `}
`;

export const Title = styled.h1`
    margin: 0;
    font-size: 2.5em;
    line-height: 4rem;
    color: ${({ theme }) => theme.colors.blue};
    font-family: ${({ theme }) => theme.fonts.verdana};
    position: relative;

    margin-bottom: 1em;

    ${media.tablet`
        font-size: 3.5em;
        line-height: 5.2rem;
    `}
`;

export const ContentWrap = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    ${media.tablet`
        flex-direction: row;
    `};
`;

const visible = css`
    transform: translateZ(0);
    opacity: 1;
`;

const invisible = css`
    transition: none;

    opacity: 0;
    transform: translate3d(0, 100px, 0);
`;

export const Content = styled.div<IContent>`
    width: 100%;

    transition-delay: 0.2s;
    transition-timing-function: ease;
    transition-duration: 1.5s;
    transition-property: opacity, transform, -webkit-transform;

    ${({ animated }) => (animated ? visible : invisible)};

    display: flex;
    flex-direction: column;
    margin: 5em 0 0 0;

    ${media.tablet`
        margin: 0 0 0 5em;
    `};
    ${media.desktop`
        margin: 0 0 0 7em;
    `};
`;

export const ContentTitle = styled.h1`
    margin: 0;
    font-size: 2.5em;
    line-height: 4rem;
    position: relative;
    color: ${({ theme }) => theme.colors.blue};
    font-family: ${({ theme }) => theme.fonts.verdana};

    margin: 1.4rem 0;

    ${media.tablet`
        font-size: 2.4em;
        line-height: 3.7rem;
    `}
`;

export const ContentText = styled.p`
    display: block;
    width: 100%;

    font-size: 1.5rem;
    line-height: 3.2rem;
    font-family: ${({ theme }) => theme.fonts.openSans};

    color: ${({ theme }) => theme.colors.gray};
`;
