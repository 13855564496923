import React from "react";

export const dictionaryData = [
    {
        icon: "akcie",
        title: "Akcie",
        content:
            "Akcie je cenný papír představující přímý podíl akcionáře (držitele akcie) na základním kapitálu emitenta (akciové společnosti, která akcii vydala). S akciemi je spojeno zejména právo podílet se na řízení emitující společnosti prostřednictvím hlasování na valné hromadě a právo na výplatu dividendy (podílu na zisku emitenta). Akcie jsou prostředkem pro středně až dlouhodobé zhodnocování majetku. Správně provedený výběr akcií může přinést dvojí zisk: růst investovaného kapitálu a příjem z vyplácených dividend.",
    },
    {
        icon: "akciove-fondy",
        title: "Akciové fondy",
        content:
            "Akciové fondy jsou určeny investorům, kteří chtějí nechat své peníze vydělávat pod dohledem profesionálů. Svěřené prostředky jsou spravovány manažery fondů, kteří je investují do širokého spektra aktiv. Tím se snaží maximalizovat zisk a současně snížit rizikovost fondu. Volba konkrétního fondu závisí na strategii a době, po kterou chce investor peníze uložit. Při investování do podílových fondů investor obdrží tzv. podílový list.",
    },
    {
        icon: "dluhopisove-fondy",
        title: "Dluhopisové fondy a fondy peněžního trhu",
        content:
            "Dluhopisové fondy a fondy peněžního trhu jsou často používány jako alternativy bankovních produktů. Jejich nízká volatilita je spojena s nízkým potenciálním výnosem. Fondy tohoto typu investují do dluhových cenných papírů a jsou vhodné pro krátkodobé až střednědobé investice.",
    },
    {
        icon: "dluhopisy",
        title: "Korporátní dluhopisy",
        content:
            "V případě korporátních dluhopisů se jedná o závazek, v němž dlužník slibuje splatit věřiteli k předem stanovenému datu vypůjčenou částku společně s výnosem ve formě jedné či více kuponových plateb. Kuponové výnosy jsou obvykle splatné v ročních nebo půlročních intervalech od data emise. Korporátní dluhopisy se vydávají se splatností i několik desítek let.",
    },
    {
        icon: "etf",
        title: "ETF (burzovně obchodované fondy)",
        content:
            "ETF (Exchange-Traded Funds) jsou inovativní finanční instrumenty umožňující nákup například celé báze zvoleného akciového indexu jedním obchodem. Jejich pomocí lze investice rychle, jednoduše a levně diverzifikovat. Díky tomu, že indexové akcie kopírují již existující a veřejně dostupné indexy, navíc odpadá riziko netransparentnosti a nepředvídatelnosti. Skrze ETF lze investovat i do komodit, měnových párů či korporátních dluhopisů.",
    },
    {
        icon: "certifikaty",
        title: "Investiční certifikáty",
        content:
            "Investiční certifikáty představují zvláštní typ dlužního úpisu. Emitent certifikátu může dočasně nakládat s peněžními prostředky investora. Investor si později nechá certifikát proplatit, a tak může realizovat zisk. Základ certifikátů tvoří podkladové aktivum, kterým mohou být například jednotlivé akcie, akciové či dluhopisové indexy, měny nebo komodity. Cena certifikátu se odvíjí od vývoje hodnoty podkladového aktiva. Záleží však na typu certifikátu, jakou měrou se změna ceny podkladového aktiva promítne do jeho ceny. Prostřednictvím certifikátů lze spekulovat jak na růst, tak i na pokles podkladového aktiva.",
    },
    {
        icon: "warranty",
        title: "Warranty",
        content:
            "Warranty jsou vysoce rizikové cenné papíry, které představují právo koupit (call warrant) nebo prodat (put warrant) předem dohodnuté množství podkladového aktiva v pevně stanovený den za předem určenou realizační cenu (strike price). Investováním do call warrantu očekáváme, že cena podkladového aktiva poroste, a tím také cena samotného call warrantu. Put warrant se chová opačně – jeho cena roste tehdy, když cena podkladového aktiva klesá. V případě put warrantu tedy investor vydělává, pokud trh klesá a podkladové aktivum ztrácí svoji hodnotu. Warranty mohou sloužit jako prostředek k zajištění portfolia i jako nástroj ke spekulaci.",
    },
    {
        icon: "cilovytrh",
        title: "Cílový trh",
        content: (
            <p>
                <span style={{ color: "#46E08C" }}>Pozitivním</span> cílovým
                trhem je skupina zákazníků, jejichž investiční preference a
                vlastnosti se shodují s investičními preferencemi a vlastnostmi
                zákazníka, pro kterého je konkrétní investiční produkt určen
                jeho tvůrcem. V případě, že jste zákazníkem v pozitivním cílovém
                trhu pro daný investiční produkt, Patria Vám investiční produkt
                může bez dalšího umožnit k nákupu nebo aktivně nabízet.
                <br />
                <br />
                <span style={{ color: "#F63838" }}>Negativním</span> cílovým
                trhem je naopak skupina zákazníků, jejichž investiční preference
                a vlastnosti se shodují s investičními preferencemi a
                vlastnostmi zákazníka, pro kterého není konkrétní investiční
                produkt určen jeho tvůrcem. V případě že jste zákazníkem v
                negativním cílovém trhu pro daný investiční produkt, Patria Vám
                investiční produkt nemůže umožnit k nákupu ani aktivně nabízet.
                <br />
                <br />
                <span style={{ color: "#B9B9B9" }}>Neutrálním</span> cílovým
                trhem je skupina zákazníků, jejichž investiční preference a
                vlastnosti se neshodují s investičními preferencemi a
                vlastnostmi zákazníka nacházejícím se v pozitivním nebo
                negativním cílovém trhu určeném tvůrcem pro daný investiční
                produkt. V případě že jste pro daný investiční produkt
                zákazníkem v neutrálním cílovém trhu, investiční produkt Vám
                Patria umožní k nákupu jen ve vybraných režimech v závislosti na
                důvodu zařazení do neutrálního trhu, avšak nemůže jej aktivně
                nabízet.
                <br />
                <br /> Na to, aby Patria vyhodnotila, zda jste
                pozitivním/neutrálním/negativním cílovým trhem a zda spadáte do
                daného okruhu zákazníků či nikoliv, musí zjistit zejména: jaký
                typ zákazníka jste, jaké máte znalosti a zkušenosti, jaké je
                Vaše finanční zázemí, jaká je Vaše tolerance k riziku a v
                neposlední řadě Vaše investiční cíle.
            </p>
        ),
    },
    {
        icon: "primerenost",
        title: "Přiměřenost",
        content:
            "Přiměřenost investičního produktu nebo investiční služby pro zákazníka je vyhodnocována na základě jeho znalostí a zkušeností s daným investičním produktem nebo investiční službou. Patria považuje investiční produkt pro klienta přiměřený nebo investiční službu přiměřenou pouze v případě, pokud jsou zákazníkovi znalosti a zkušenosti s daným investičním produktem či službou dostatečné pro to, aby zákazník dostatečně pochopil jejich podstatu včetně relevantních rizik. V návaznosti na míru komplexity a složitosti produktu či služby může být pro určení přiměřenosti po zákazníkovi vyžadován různý rozsah jeho znalostí a zkušeností.",
    },
    {
        icon: "profil",
        title: "Rizikový profil",
        content:
            "Rizikový profil zákazníka je definován jako přístup a postoj zákazníka k rizikům investice a potenciálním výkyvům na finančních trzích. Rizikový profil Patria vyhodnocuje na základě informací, které jí zákazník poskytnul ve vztahu k zamýšlenému investičnímu horizontu a ve vztahu k jeho chování v modelových situacích týkajících se propadu v hodnotě zakoupených investičních nástrojů. Patria progresivně vyhodnocuje tyto atributy kombinovaně za předpokladu, že riziko dané investice je v delším časovém horizontu nižší než v kratším časovém horizontu a naopak. Zákazníkovi s daným rizikovým profilem je umožněno obchodovat pouze s takovými investičními nástroji, které jsou pro jeho rizikový profil určeny. Skutečnost, který investiční nástroj je určen pro který rizikový profil, je určena na základě indikátorů rizikovosti/návratnosti investice a tedy volatility (SRI/SRRI) určeným tvůrcem investičního nástroje, nebo na základě rozhodnutí Patrie v souladu s jejími zkušenostmi a znalostmi finančních trhů.",
    },
    {
        icon: "vhodnost",
        title: "Vhodnost (investiční poradenství)",
        content:
            "Vyhodnocení vhodnosti je podstatou služby investičního poradenství. K tomu, aby Vám mohl makléř poskytnout investiční poradenství, je nezbytné, aby pro Vás byl daný investiční produkt nebo investiční služba vhodná. Je-li pro Vás investiční služba nebo investiční produkt vhodný, znamená to, že Vaše znalosti a zkušenosti, finanční zázemí, postoj k riziku a investiční cíle byly zhodnoceny jako dostatečné. V tomto případě Vám může makléř poskytnout investiční poradenství.",
    },
];
