import React from "react";

interface IIcon {
    color?: string;
}

const CilovyTrhIcon: React.FC<IIcon> = props => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 4C10.067 4 8.5 5.567 8.5 7.5C8.5 9.433 10.067 11 12 11C13.933 11 15.5 9.433 15.5 7.5C15.5 5.567 13.933 4 12 4ZM12 6C12.827 6 13.5 6.673 13.5 7.5C13.5 8.327 12.827 9 12 9C11.173 9 10.5 8.327 10.5 7.5C10.5 6.673 11.173 6 12 6ZM4.5 7C3.119 7 2 8.119 2 9.5C2 10.881 3.119 12 4.5 12C5.881 12 7 10.881 7 9.5C7 8.119 5.881 7 4.5 7ZM19.5 7C18.119 7 17 8.119 17 9.5C17 10.881 18.119 12 19.5 12C20.881 12 22 10.881 22 9.5C22 8.119 20.881 7 19.5 7ZM4.5 8.72266C4.929 8.72266 5.27734 9.071 5.27734 9.5C5.27734 9.929 4.929 10.2773 4.5 10.2773C4.071 10.2773 3.72266 9.929 3.72266 9.5C3.72266 9.071 4.071 8.72266 4.5 8.72266ZM19.5 8.72266C19.929 8.72266 20.2773 9.071 20.2773 9.5C20.2773 9.929 19.929 10.2773 19.5 10.2773C19.071 10.2773 18.7227 9.929 18.7227 9.5C18.7227 9.071 19.071 8.72266 19.5 8.72266ZM12 13C10.5253 13 8.12583 13.4683 6.55469 14.3984C5.91353 14.1553 5.21125 14 4.5 14C2.263 14 0 15.3976 0 16.9316V20H4.5H5H6H19H19.5H24V16.9316C24 15.3976 21.737 14 19.5 14C18.7887 14 18.0865 14.1553 17.4453 14.3984C15.8742 13.4683 13.4747 13 12 13ZM12 15C14.134 15 16.622 15.9614 17 16.5664V18H7V16.5664C7.378 15.9614 9.866 15 12 15ZM4.5 16C4.69795 16 4.8767 16.034 5.05859 16.0625C5.02144 16.2034 5 16.3494 5 16.5V18H4.5H2V17.0098C2.184 16.6898 3.198 16 4.5 16ZM19.5 16C20.802 16 21.816 16.6898 22 17.0098V18H19.5H19V16.5C19 16.3494 18.9786 16.2034 18.9414 16.0625C19.1233 16.034 19.3021 16 19.5 16Z"
                fill={props.color}
            />
        </svg>
    );
};

export default CilovyTrhIcon;
