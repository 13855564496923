import React from "react";

interface IIcon {
    color?: string;
}

const PrimerenostIcon: React.FC<IIcon> = props => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 4C11.6492 4.00028 11.3046 4.09282 11.0009 4.26834C10.6972 4.44387 10.4449 4.69619 10.2695 5H2V7H4L0 15C0 17.761 2.239 20 5 20C7.761 20 10 17.761 10 15L6 7H10.2715C10.4467 7.30352 10.6986 7.55566 11.002 7.73117C11.3054 7.90667 11.6495 7.99938 12 8C12.3508 7.99972 12.6954 7.90718 12.9991 7.73166C13.3028 7.55613 13.5551 7.30381 13.7305 7H18L14 15C14 17.761 16.239 20 19 20C21.761 20 24 17.761 24 15L20 7H22V5H13.7285C13.5533 4.69648 13.3014 4.44434 12.998 4.26883C12.6946 4.09333 12.3505 4.00062 12 4ZM5 9.02148L7.48828 14H2.51172L5 9.02148ZM19 9.02148L21.4883 14H16.5117L19 9.02148ZM2.18359 16H7.81641C7.40332 17.1628 6.30463 18 5 18C3.69537 18 2.59668 17.1628 2.18359 16ZM16.1836 16H21.8164C21.4033 17.1628 20.3046 18 19 18C17.6954 18 16.5967 17.1628 16.1836 16Z"
                fill={props.color}
            />
        </svg>
    );
};

export default PrimerenostIcon;
