import React from "react";
import { Helmet } from "react-helmet";

import DictionaryPage from "../containers/DictionaryPage";

const Dictionary = () => (
    <>
        <Helmet>
            <title>Slovník - Patria Finance</title>
        </Helmet>
        <DictionaryPage />
    </>
);

export default Dictionary;
