import React from "react";

interface IIcon {
    color?: string;
}

const VhodnostIcon: React.FC<IIcon> = props => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 3C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V9.24219L19 11.2422L19.002 19H5V5H16.7578L18.7578 3H5ZM21.293 3.29297L11 13.5859L7.70703 10.293L6.29297 11.707L11 16.4141L22.707 4.70703L21.293 3.29297Z"
                fill={props.color}
            />
        </svg>
    );
};

export default VhodnostIcon;
