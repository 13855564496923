import React from "react";

interface IIcon {
    color?: string;
}

const AkcioveFondyIcon: React.FC<IIcon> = props => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 0C5.73479 2.65169e-05 5.48045 0.105392 5.29292 0.292922C5.10539 0.480453 5.00003 0.734792 5 1V1.5V5H3C2.448 5 2 4.551 2 4C2 3.449 2.448 3 3 3V1C1.346 1 0 2.346 0 4V17C0 18.103 0.897 19 2 19H16C17.103 19 18 18.103 18 17V7C18 5.897 17.103 5 16 5H15V1C15 0.734792 14.8946 0.480453 14.7071 0.292922C14.5195 0.105392 14.2652 2.65169e-05 14 0H6.5H6ZM7.91211 2H12.0879C12.1625 2.21031 12.2831 2.40132 12.4409 2.55911C12.5987 2.7169 12.7897 2.83749 13 2.91211V5H11C11 4.448 10.552 4 10 4C9.448 4 9 4.448 9 5H7V2.91211C7.21031 2.83749 7.40131 2.7169 7.55911 2.55911C7.7169 2.40132 7.83749 2.21031 7.91211 2ZM2 6.81641C2.31439 6.92856 2.64761 7 3 7H6H16V17H2V6.81641ZM13 11C12.448 11 12 11.448 12 12C12 12.552 12.448 13 13 13C13.552 13 14 12.552 14 12C14 11.448 13.552 11 13 11Z"
                fill={props.color}
            />
        </svg>
    );
};

export default AkcioveFondyIcon;
